import { template as template_a16a27fcd7b9461eb0763231c1186416 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a16a27fcd7b9461eb0763231c1186416(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
