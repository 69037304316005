import { template as template_e613f6be37e74c6bb57b551815815bec } from "@ember/template-compiler";
const FKLabel = template_e613f6be37e74c6bb57b551815815bec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
