import { template as template_6424b8cf608b480886f9cd86cdd7711e } from "@ember/template-compiler";
const WelcomeHeader = template_6424b8cf608b480886f9cd86cdd7711e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
